export const SESSION_LIVE_STREAM_CONFIG_FRAGMENT = `
  fragment sessionLiveStreamConfigFragment on Session {
    uid
    id
    schemaCode
    name
    videoLiveRTMPEndpoint
    videoLiveRTMPKey
  }
`;
